<template>
  <Banner class="mb-25" />

  <RegistrationBanner
    v-if="showBanner"
    class="mb-25"
    @close="hideBanner"
  />
  <!-- A C T I V I T I E S   D E S K T O P -->
  <!-- Need to remove element from DOM because of the select component tooltip issue -->
  <div v-if="screen.width > 769">
    <UpcomingActivities
      :activities-list="upcomingActivitiesList"
      :headers="tableHeaders"
      :external-link="externalLink"
      :open-modal="openRegisterModal"
      class="mb-25"
      @browse="handleBrowsing"
      @confirm="confirmRegistration"
      @discard="discardRegistration"
      @register="handleRegistration"
    >
      <!-- T I T L E -->
      <template #title>
        <div class="flex justify-between items-center">
          <p class="text-lucky-orange text-md md:text-1xl">Explore Activities</p>
          <AppSelect
            v-model="exploreActivitiesFilter"
            :options="filterOptions"
            option-key="name"
            key-value="id"
            @change="fetchActivities"
          />
        </div>
      </template>

      <!-- P A G I N A T I O N -->
      <template
        v-if="upcomingActivitiesList.length"
        #pagination
      >
        <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
          <PaginationCounter
            :offset="exploreActivitiesOffset"
            :limit="ACTIVITIES_PAGINATION_LIMIT"
            :total="withoutRegistrationCount"
            class="text-posey-blue"
          />

          <v-pagination
            :page-size="ACTIVITIES_PAGINATION_LIMIT"
            layout="prev, pager, next"
            hide-on-single-page
            :total="withoutRegistrationCount"
            @current-change="(val) => handlePageChanged('exploreActivitiesPage', val)"
          />
        </div>
      </template>

      <!-- N O  D A T A -->
      <template
        v-if="exploreActivitiesFilter.id !== 'all'"
        #noData
      >
        <div class="flex flex-col justify-center items-center">
          <p class="pt-60 text-grey-fp-70">There are no activities for this category.</p>
          <span
            class="text-blue-fp-35 pt-10 pb-15 cursor-pointer"
            @click="resetFilter('explore')"
          >
            Reset Filter
          </span>
        </div>
      </template>
    </UpcomingActivities>

    <RegisteredActivities
      :activities-list="registeredActivitiesList"
      :headers="tableHeaders"
      :external-link="externalLink"
      :open-modal="openCancellationModal"
      @browse="handleBrowsing"
      @cancel="handleCancellation"
      @discard="discardCancellation"
      @confirm="confirmCancellation"
    >
      <!-- T I T L E -->
      <template #title>
        <div class="flex justify-between items-center">
          <p class="text-lucky-orange text-md md:text-1xl">My Activities</p>
          <AppSelect
            v-model="myActivitiesFilter"
            :options="filterOptions"
            option-key="name"
            key-value="id"
            @change="fetchActivities"
          />
        </div>
      </template>

      <!-- P A G I N A T I O N -->
      <template
        v-if="registeredActivitiesList.length"
        #pagination
      >
        <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
          <PaginationCounter
            :offset="myActivitiesOffset"
            :limit="ACTIVITIES_PAGINATION_LIMIT"
            :total="registeredCount"
            class="text-posey-blue"
          />

          <v-pagination
            :page-size="ACTIVITIES_PAGINATION_LIMIT"
            layout="prev, pager, next"
            hide-on-single-page
            :total="registeredCount"
            @current-change="(val) => handlePageChanged('myActivitiesPage', val)"
          />
        </div>
      </template>

      <!-- N O  D A T A -->
      <template
        v-if="myActivitiesFilter.id !== 'all'"
        #noData
      >
        <div class="flex flex-col justify-center items-center">
          <p class="pt-60 text-grey-fp-70">There are no activities for this category.</p>
          <span
            class="text-blue-fp-35 pt-10 pb-15 cursor-pointer"
            @click="resetFilter('registered')"
          >
            Reset Filter
          </span>
        </div>
      </template>
    </RegisteredActivities>
  </div>

  <!-- A C T I V I T I E S   M O B I L E -->
  <div v-else>
    <AppTabs
      v-model="activeTab"
      custom-tab-classes="w-full text-center"
      :items="tabs"
    />

    <UpcomingActivities
      v-if="activeTab.value === 'available'"
      :activities-list="upcomingActivitiesList"
      :headers="tableHeaders"
      :external-link="externalLink"
      :open-modal="openRegisterModal"
      @browse="handleBrowsing"
      @confirm="confirmRegistration"
      @discard="discardRegistration"
      @register="handleRegistration"
    >
      <template #title>
        <AppSelect
          v-model="exploreActivitiesFilter"
          :options="filterOptions"
          option-key="name"
          key-value="id"
          class="w-full mb-12"
          @change="fetchActivities"
        />
      </template>
      <template
        v-if="exploreActivitiesFilter.id !== 'all'"
        #noData
      >
        <div class="flex flex-col justify-center items-center">
          <p class="pt-60 text-grey-fp-70">There are no activities for this category.</p>
          <span
            class="text-blue-fp-35 pt-10 pb-15 cursor-pointer"
            @click="resetFilter('explore')"
          >
            Reset Filter
          </span>
        </div>
      </template>

      <template
        v-if="withoutRegistrationCount > upcomingActivitiesList.length"
        #pagination
      >
        <div class="flex mt-10">
          <AppButton
            type="primary"
            class="mr-10 px-20 border-primary w-full"
            plain
            @click="loadMoreActivities('upcoming')"
          >
            Load
            {{ withoutRegistrationCount - upcomingActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
              ? ACTIVITIES_PAGINATION_LIMIT
              : withoutRegistrationCount - upcomingActivitiesList.length }}
            more
          </AppButton>
        </div>
      </template>
    </UpcomingActivities>

    <RegisteredActivities
      v-else
      :activities-list="registeredActivitiesList"
      :headers="tableHeaders"
      :external-link="externalLink"
      :open-modal="openCancellationModal"
      @browse="handleBrowsing"
      @cancel="handleCancellation"
      @discard="discardCancellation"
      @confirm="confirmCancellation"
    >
      <template #title>
        <AppSelect
          v-model="myActivitiesFilter"
          :options="filterOptions"
          option-key="name"
          key-value="id"
          class="w-full mb-12"
          @change="fetchActivities"
        />
      </template>
      <template
        v-if="myActivitiesFilter.id !== 'all'"
        #noData
      >
        <div class="flex flex-col justify-center items-center">
          <p class="pt-60 text-grey-fp-70">There are no activities for this category.</p>
          <span
            class="text-blue-fp-35 pt-10 pb-15 cursor-pointer"
            @click="resetFilter('registered')"
          >
            Reset Filter
          </span>
        </div>
      </template>

      <template
        v-if="registeredCount > registeredActivitiesList.length"
        #pagination
      >
        <div class="flex mt-10">
          <AppButton
            type="primary"
            class="mr-10 px-20 border-primary w-full"
            plain
            @click="loadMoreActivities('registered')"
          >
            Load
            {{ registeredCount - registeredActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
              ? ACTIVITIES_PAGINATION_LIMIT
              : registeredCount - registeredActivitiesList.length }}
            more
          </AppButton>
        </div>
      </template>
    </RegisteredActivities>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, reactive, toRefs, ref, onBeforeMount } from 'vue';
  import { useScreen } from 'vue-screen';
  import { useToast } from "vue-toastification";

  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';
  import Banner from '@/components/Banner.vue';

  import { ITableHeaders, TIndexedObject, ICategory, ICategoryRes } from '@/types';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { vuex } from '@/store';
  import { categoriesService } from '@/services';
  import {
    registeredActivitiesParams,
    activitiesWithoutRegistrationsParams,
    ACTIVITIES_PAGINATION_LIMIT,
    EXPLORE_ACTIVITIES_CATEGORIES
  } from '@/views/user-options/api-params';

  export default defineComponent({
    name: 'ExploreActivities',

    components: {
      UpcomingActivities,
      RegisteredActivities,
      AppTabs,
      AppSelect,
      RegistrationBanner,
      PaginationCounter,
      AppButton,
      Banner
    },

    setup() {
      const screen = useScreen();
      const toast = useToast();
      const showBanner = ref<boolean>(true);
      const activeChildId = ref<string>('');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const state = reactive({
        activeTab: { value: 'available', label: 'Available' } as TIndexedObject,
        myActivitiesFilter: {} as { id: number | string, name: string },
        exploreActivitiesFilter: {} as { id: number | string, name: string },
        filterOptions: [] as Array<{ id: number | string, name: string }>
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        upcomingActivitiesList,
        registeredActivitiesList,
        registeredCount,
        withoutRegistrationCount,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: fetchActivities
      });

      const {
        activeTab,
        myActivitiesFilter,
        exploreActivitiesFilter,
        filterOptions
      } = toRefs(state);

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description',minWidth: 500 },
        { property: 'startDate', label: 'Start Date', minWidth: 140 },
        { property: 'endDate', label: 'End Date', minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        fetchActivities();
      }

      function hideBanner() {
        showBanner.value = false;
      }

      function resetFilter(value: string) {
        if (value === 'registered') {
          myActivitiesFilter.value = { id: 'all', name: 'All' };
        } else {
          exploreActivitiesFilter.value = { id: 'all', name: 'All' };
        }

        fetchActivities();
      }


      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredActivitiesParams(
              myActivitiesFilter.value.id,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(
              exploreActivitiesFilter.value.id,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      async function fetchActivities() {
        vuex.setLoading(true);

        return Promise.all([
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(exploreActivitiesFilter.value.id, exploreActivitiesOffset.value)
          ),
          fetchRegisteredActivities(
            registeredActivitiesParams(myActivitiesFilter.value.id, myActivitiesOffset.value)
          )
        ])
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });

      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return categoriesService.fetchCategories(EXPLORE_ACTIVITIES_CATEGORIES)
          .then((res: ICategoryRes) => {
            filterOptions.value = [
              { id: 'all', name: 'All' },
              ...res.data.map(({ id, name }: ICategory) => ({ id, name }))
            ];
            myActivitiesFilter.value = filterOptions.value[0];
            exploreActivitiesFilter.value = filterOptions.value[0];

            return Promise.all([
              fetchActivitiesWithoutRegistrations(
                activitiesWithoutRegistrationsParams(exploreActivitiesFilter.value.id, exploreActivitiesOffset.value)
              ),
              fetchRegisteredActivities(
                registeredActivitiesParams(myActivitiesFilter.value.id, myActivitiesOffset.value)
              )
            ]);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        upcomingActivitiesList,
        registeredActivitiesList,
        showBanner,
        tabs,
        activeTab,
        tableHeaders,
        myActivitiesFilter,
        exploreActivitiesFilter,
        filterOptions,
        registeredCount,
        withoutRegistrationCount,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,

        activeChildId,
        vuex,
        screen,

        externalLink,
        openCancellationModal,
        openRegisterModal,

        hideBanner,
        resetFilter,
        handlePageChanged,
        loadMoreActivities,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        fetchActivities,
        confirmRegistration,
        discardRegistration,
        handleRegistration,
      };
    }

  });
</script>