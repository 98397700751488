
  import { defineComponent, computed, reactive, toRefs, ref, onBeforeMount } from 'vue';
  import { useScreen } from 'vue-screen';
  import { useToast } from "vue-toastification";

  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';
  import Banner from '@/components/Banner.vue';

  import { ITableHeaders, TIndexedObject, ICategory, ICategoryRes } from '@/types';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { vuex } from '@/store';
  import { categoriesService } from '@/services';
  import {
    registeredActivitiesParams,
    activitiesWithoutRegistrationsParams,
    ACTIVITIES_PAGINATION_LIMIT,
    EXPLORE_ACTIVITIES_CATEGORIES
  } from '@/views/user-options/api-params';

  export default defineComponent({
    name: 'ExploreActivities',

    components: {
      UpcomingActivities,
      RegisteredActivities,
      AppTabs,
      AppSelect,
      RegistrationBanner,
      PaginationCounter,
      AppButton,
      Banner
    },

    setup() {
      const screen = useScreen();
      const toast = useToast();
      const showBanner = ref<boolean>(true);
      const activeChildId = ref<string>('');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const state = reactive({
        activeTab: { value: 'available', label: 'Available' } as TIndexedObject,
        myActivitiesFilter: {} as { id: number | string, name: string },
        exploreActivitiesFilter: {} as { id: number | string, name: string },
        filterOptions: [] as Array<{ id: number | string, name: string }>
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        upcomingActivitiesList,
        registeredActivitiesList,
        registeredCount,
        withoutRegistrationCount,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: fetchActivities
      });

      const {
        activeTab,
        myActivitiesFilter,
        exploreActivitiesFilter,
        filterOptions
      } = toRefs(state);

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description',minWidth: 500 },
        { property: 'startDate', label: 'Start Date', minWidth: 140 },
        { property: 'endDate', label: 'End Date', minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        fetchActivities();
      }

      function hideBanner() {
        showBanner.value = false;
      }

      function resetFilter(value: string) {
        if (value === 'registered') {
          myActivitiesFilter.value = { id: 'all', name: 'All' };
        } else {
          exploreActivitiesFilter.value = { id: 'all', name: 'All' };
        }

        fetchActivities();
      }


      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredActivitiesParams(
              myActivitiesFilter.value.id,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(
              exploreActivitiesFilter.value.id,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      async function fetchActivities() {
        vuex.setLoading(true);

        return Promise.all([
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(exploreActivitiesFilter.value.id, exploreActivitiesOffset.value)
          ),
          fetchRegisteredActivities(
            registeredActivitiesParams(myActivitiesFilter.value.id, myActivitiesOffset.value)
          )
        ])
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });

      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return categoriesService.fetchCategories(EXPLORE_ACTIVITIES_CATEGORIES)
          .then((res: ICategoryRes) => {
            filterOptions.value = [
              { id: 'all', name: 'All' },
              ...res.data.map(({ id, name }: ICategory) => ({ id, name }))
            ];
            myActivitiesFilter.value = filterOptions.value[0];
            exploreActivitiesFilter.value = filterOptions.value[0];

            return Promise.all([
              fetchActivitiesWithoutRegistrations(
                activitiesWithoutRegistrationsParams(exploreActivitiesFilter.value.id, exploreActivitiesOffset.value)
              ),
              fetchRegisteredActivities(
                registeredActivitiesParams(myActivitiesFilter.value.id, myActivitiesOffset.value)
              )
            ]);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        upcomingActivitiesList,
        registeredActivitiesList,
        showBanner,
        tabs,
        activeTab,
        tableHeaders,
        myActivitiesFilter,
        exploreActivitiesFilter,
        filterOptions,
        registeredCount,
        withoutRegistrationCount,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,

        activeChildId,
        vuex,
        screen,

        externalLink,
        openCancellationModal,
        openRegisterModal,

        hideBanner,
        resetFilter,
        handlePageChanged,
        loadMoreActivities,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        fetchActivities,
        confirmRegistration,
        discardRegistration,
        handleRegistration,
      };
    }

  });
